// 处理日期 2020-11-24 -> 2020年11月6日
const filterTime = value => {
  if (value) {
    let timerArr = value.split("-");
    let timer = timerArr[0] + "年" + timerArr[1] + "月" + timerArr[2] + "日";
    return timer;
  }
  return value;
};

// 处理日期 时间戳 -> 2020年11月6日
const formatDate = date => {
  date=date.replace(/-/g, "/")
  date = new Date(date);
  var YY = date.getFullYear() + "年";
  var MM =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "月";
  var DD = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "日";
  return YY + MM + DD;
};

export { filterTime, formatDate };
