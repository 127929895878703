<template>
  <div class="loading" v-loading.fullscreen="loadingShow"></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["loadingShow"])
  },
  created() {}
};
</script>

<style lang="scss"></style>
