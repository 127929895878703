import { URL } from "@/constants/index.js";
import axios from "@/config/axios.config";
// import switchParams from "../js/switchParams";

// 获取区域列表
export async function areaList(params, options = {}) {
  return axios({
    url: URL.areaList,
    method: "get",
    params,
    ...options
  });
}

// 获取诊所列表
export async function clinicList(params, options = {}) {
  return axios({
    url: URL.clinicList,
    method: "get",
    params,
    ...options
  });
}

// 获取时间表
export async function calendar(params, options = {}) {
  return axios({
    url: URL.calendar,
    method: "get",
    params,
    ...options
  });
}

// 提交个人预约信息
export async function complete(data, options = {}) {
  return axios({
    url: URL.complete,
    method: "post",
    data,
    ...options
  });
}

// 验证预约番号和邮箱
export async function confirm(data, options = {}) {
  return axios({
    url: URL.confirm,
    method: "post",
    data,
    ...options
  });
}

// 添加同行人信息
export async function companion(data, options = {}) {
  return axios({
    url: URL.companion,
    method: "post",
    data,
    ...options
  });
}

// 查询问卷调查
export async function findQuestion(params, options = {}) {
  return axios({
    url: URL.findQuestion,
    method: "get",
    params,
    ...options
  });
}

// 提交问卷
export async function saveQuestion(data, options = {}) {
  return axios({
    url: URL.saveQuestion,
    method: "post",
    data,
    ...options
  });
}

// 获取诊所接口
export async function hopeChange(params, options = {}) {
  return axios({
    url: URL.hopeChange,
    method: "get",
    params,
    ...options
  });
}

// 预约变更获取时间接口
export async function getHopeTime(data, options = {}) {
  return axios({
    url: URL.getHopeTime,
    method: "post",
    data,
    ...options
  });
}

// 提交变更的希望数据
export async function submitHome(data, options = {}) {
  return axios({
    url: URL.submitHome,
    method: "post",
    data,
    ...options
  });
}

// 取消预约
export async function cancel(data, options = {}) {
  return axios({
    url: URL.cancel,
    method: "post",
    data,
    ...options
  });
}

// 添加header
export async function header(params, options = {}) {
  return axios({
    url: URL.header,
    method: "get",
    params,
    ...options
  });
}

// hash
export async function login(params, options = {}) {
  return axios({
    url: URL.login,
    method: "get",
    params,
    ...options
  });
}

// 礼品介绍 首页确认接口
export async function homeConfirm(data, options = {}) {
  return axios({
    url: URL.homeConfirm,
    method: "post",
    data,
    ...options
  });
}

// 选择优惠礼品
export async function choosePresent(data, options = {}) {
  return axios({
    url: URL.choosePresent,
    method: "post",
    data,
    ...options
  });
}

// 优惠确认
export async function presentConfirm(data, options = {}) {
  return axios({
    url: URL.presentConfirm,
    method: "post",
    data,
    ...options
  });
}

// 优惠确认
export async function contactConfirm(data, options = {}) {
  return axios({
    url: URL.contactConfirm,
    method: "post",
    data,
    ...options
  });
}

// 优惠确认
export async function ontactSend(data, options = {}) {
  return axios({
    url: URL.ontactSend,
    method: "post",
    data,
    ...options
  });
}